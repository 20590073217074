<template>
	<footer class="footer">
		<div class="footer__content">
			<FooterContact v-if="showFooterContact" />
			<div class="footer__info">
				<div class="footer__info__logo">
					<!-- <img src="../assets/images/logo/img_logo_wht.svg" alt="" /> -->
				</div>
				<div class="footer__details">
					<div class="footer__info__location">
						<div class="footer__loc--tokyo">
							<div class="footer__loc__header footer__loc__header--underline">
								<h1>Tokyo Office</h1>
							</div>
							<div class="footer__loc__details">
								<p class="footer__loc__company">株式会社decode</p>
								<p class="footer__loc__address">
									東京都港区六本木七丁目7番7号Tri-Seven Roppongi 8階
								</p>
								<p class="footer__loc__tel">TEL: 03-6629-3586</p>
							</div>
						</div>
						<div class="footer__loc--manila">
							<div class="footer__loc__header footer__loc__header--underline">
								<h1>Manila Office</h1>
							</div>
							<div class="footer__loc__details">
								<p class="footer__loc__company">decode Inc.</p>
								<p class="footer__loc__address">
									U2518 98 Herrera Tower VA Runfino st.Salcedo Village, Bel-Air,
									Makati, Philippines
								</p>
							</div>
						</div>
					</div>

					<div class="footer__links--sns">
						<div class="footer__links__header--underline">
							<h1>Socials</h1>
						</div>
						<div class="footer__links__list">
							<a href="https://www.facebook.com/decodetokyo/" target="_blank" rel="noopener noreferrer">Facebook</a>
							<a href="https://twitter.com/decodetokyo" target="_blank" rel="noopener noreferrer">Twitter</a>
							<a href="https://www.instagram.com/decodetokyo/" target="_blank" rel="noopener noreferrer"
								>Instagram</a
							>
						</div>
					</div>
					<div class="footer__links--mail">
						<div class="footer__links__header--underline">
							<h1>Say Hi!</h1>
						</div>
						<div class="footer__links__list">
							<a href="mailto:info@decodetokyo.com" target="_blank" rel="noopener noreferrer"
								>info@decodetokyo.com</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer__info__copyright">
			<div class="footer__copyright">
				<p>Copyright &copy; 2021 decode. <wbr />All Rights Reserved.</p>
			</div>
		</div>
	</footer>
</template>

<script>
import CTA from "../components/CTA.vue";
import FooterContact from "../components/FooterContact.vue";
export default {
	components: { CTA, FooterContact },
	name: "FooterContent",

	computed: {
		showFooterContact() {
			return this.$route.path !== "/contact";
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped>

@import "FooterContent.scss";

</style>