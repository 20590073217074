<template>
	<div>
		<div v-if="!error && !isLoading && filterCards.length !== 0">
			<div
				class="projects"
				id="projects"
				@mousemove="updateCoordinates($event)"
			>
				<div class="cardlist">
					<div class="cardlist__grid">
						<!-- Transition of cards on render for filter animation -->
						<transition-group
							v-on:before-enter="beforeEnter"
							v-on:enter="enter"
							v-on:enter-cancelled="enterCancelled"
							v-on:leave="leave"
							v-on:leave-cancelled="leaveCancelled"
							name="staggered-ani"
							appear
						>
							<div
								v-for="(item, index) in filterCards"
								:key="item"
								:data-index="index"
								class="cardlist__loop"
							>
								<!-- Card animation upon entering viewport -->
								<!-- Can change zoom-in-up to fade-in-up to match transition -->
								<div
									data-aos="fade-up"
									data-aos-duration="1000"
									data-aos-anchor-placement="top-bottom"
									class="cardlist__condition"
								>
									<Card
										:item="item"
										@mouseenter="setMaskSize()"
										@mouseleave="resetMaskSize()"
									/>
								</div>
							</div>
						</transition-group>
					</div>
				</div>
				<div v-if="mobileView" class="cardlist--skeleton" id="skeleton">
					<div class="cardlist__grid cardlist__grid--skeleton">
						<transition-group
							v-on:before-enter="beforeEnter"
							v-on:enter="enter"
							v-on:enter-cancelled="enterCancelled"
							v-on:leave="leave"
							v-on:leave-cancelled="leaveCancelled"
							name="staggered-ani"
							appear
						>
							<div
								v-for="(item, index) in filterCards"
								:key="item"
								:data-index="index"
								class="cardlist__loop"
							>
								<!-- Card animation upon entering viewport -->
								<!-- Can change zoom-in-up to fade-in-up to match transition -->
								<div
									data-aos="fade-up"
									data-aos-duration="1000"
									data-aos-anchor-placement="top-bottom"
									class="cardlist__condition"
								>
									<CardSkeleton :item="item" />
								</div>
							</div>
						</transition-group>
					</div>
					<div class="cardlist--skeleton--bg"></div>
				</div>
			</div>
		</div>

		<div class="labels" v-else-if="!error && isLoading">Loading...</div>
		<div class="labels" v-else-if="filterCards.length <= 0">
			Nothing to see here
		</div>
		<div class="labels" v-else>Something went wrong</div>
	</div>
</template>

<script>
	import gsap from "gsap";
	import Card from "./Card.vue";
	import CardSkeleton from "./CardSkeleton.vue";
	import axios from "axios";
	import { isMobile } from "mobile-device-detect";
	export default {
		components: { Card, CardSkeleton },
		name: "CardList",
		props: {
			featuredOnly: Boolean,
			category: String,
		},
		data() {
			return {
				isLoading: true,
				items: [],
				mouseX: 0,
				mouseY: 0,
				size: 0,
				project: null,
				skeleton: null,
				error: false,
			};
		},
		mounted() {
			this.getData();
			window.addEventListener("wheel", this.handleWheel);
		},
		updated() {
			this.projects = document.querySelector("#projects");
			this.skeleton = document.querySelector("#skeleton");
			// console.log(this.filterCards.length);
		},
		created() {},
		unmounted() {
			window.removeEventListener("wheel", this.handleWheel);
		},
		methods: {
			handleWheel(event) {
				this.mouseY = event.pageY - this.projects.offsetTop + event.deltaY;
				// console.log(this.mouseY);
				gsap.to(".cardlist--skeleton", {
					"--y": this.mouseY,
					duration: 0.3,
				});
			},
			getData() {
				let url = "https://decode.microcms.io/api/v1/projects?limit=999&";

				let headers = {
					"X-API-KEY": "3b923ffb-7c8f-45b1-b0fb-f5a00647b288",
				};
				axios({
					method: "get",
					url,
					headers,
				})
					.then((res) => {
						res.data.contents.map((content) => {
							this.items.push(content);
						});

						this.isLoading = false;
					})
					.catch((err) => {
						this.error = true;
					});
			},
			setMaskSize() {
				this.size = 150;
				// this.skeleton.style.setProperty("--size", this.size);
				gsap.to(".cardlist--skeleton", {
					"--size": this.size,
					duration: 0.3,
				});
				// console.log(size);
			},

			resetMaskSize() {
				this.size = 0;
				// console.log(size);
				// this.skeleton.style.setProperty("--size", this.size);
				gsap.to(".cardlist--skeleton", {
					"--size": this.size,
					duration: 0.3,
				});
			},
			updateCoordinates(event) {
				this.mouseX = event.pageX - this.projects.offsetLeft;
				this.mouseY = event.pageY - this.projects.offsetTop;

				// this.skeleton.style.setProperty("--x", this.mouseX);
				// this.skeleton.style.setProperty("--y", this.mouseY);
				gsap.to(".cardlist--skeleton", {
					"--x": this.mouseX,
					"--y": this.mouseY,
					duration: 0.3,
				});
			},
			beforeEnter(el) {
				el.style.opacity = 0;
				el.style.transform = "translateY(100px)";
				//console.log("before Enter");
			},
			enter(el, done) {
				gsap.to(el, {
					opacity: 1,
					y: 0,
					duration: 0.8,
					onComplete: done,
					delay: el.dataset.index * 0.5,
				});
				//console.log("enter");
			},
			enterCancelled(el) {
				el.style.opacity = 0;
				el.style.display = "none";
			},
			leave(el, done) {
				gsap.to(el, {
					opacity: 0,
					y: 100,
					duration: 0.8,
					onComplete: done,
				});
				//console.log("leave");
			},
			leaveCancelled(el) {
				el.style.opacity = 1;
				el.style.display = "flex";
			},
		},
		computed: {
			mobileView() {
				return !isMobile;
			},
			filterCards() {
				if (this.featuredOnly) {
					return this.items.filter((item) => item.featured === true);
				} else {
					return this.items.filter((item) => {
						if (
							item.category.includes(this.category) ||
							this.category === "All"
						) {
							return item;
						}
					});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "CardList.scss";
</style>
