<template>
	<div class="footer__contact" id="container">
		<router-link to="/contact" class="footer__flex">
			<div class="footer__contact__text">
				<h1>Let's discuss our project together</h1>
			</div>
			<div class="footer__contact__cta" id="footer__cta">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 10.5">
					<g id="Layer_2" data-name="Layer 2">
						<g id="Layer_3" data-name="Layer 3">
							<path
								class="arrow-right"
								d="M20,5.44s0,0,0-.05,0,0,0,0a.34.34,0,0,0,0-.1h0a.38.38,0,0,0,0-.1s0,0,0,0l0-.05s0,0,0,0a.08.08,0,0,1,0,0l-.07-.07L15.1.15a.5.5,0,0,0-.71,0,.51.51,0,0,0,0,.71l3.89,3.89H.5a.5.5,0,0,0-.5.5.51.51,0,0,0,.5.51H18.28L14.39,9.64a.53.53,0,0,0,0,.72.54.54,0,0,0,.36.14.5.5,0,0,0,.35-.14l4.75-4.75.07-.08s0,0,0,0Z"
							/>
						</g>
					</g>
				</svg>
			</div>
		</router-link>
	</div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
	mounted() {
		if (!isMobile) {
			// get cta element
			let cta = document.querySelector("#footer__cta");
			// get container element
			let container = document.querySelector("#container");
			// get footer element
			let footer = document.querySelector("footer");

			// set initial coordinated
			let x = 0;
			let y = 0;

			// code below for initial position of floating cta
			cta.style.setProperty("--left", "100%");
			cta.style.setProperty("--top", "29%");

			// add event in the container
			container.addEventListener("mousemove", (e) => {
				// set x and y coordinate relative to page height and footer page position
				// '250' and '80' is just to center the cursor
				y = e.pageY - footer.offsetTop - 250;
				x = e.pageX - footer.offsetLeft - 80;

				// create style property for floating cta
				cta.style.setProperty("--left", x + "px");
				cta.style.setProperty("--top", y + "px");
			});

			// when mouse leave the container reset property
			container.addEventListener("mouseleave", (e) => {
				cta.style.setProperty("--left", "100%");
				cta.style.setProperty("--top", "29%");
			});
		}
	},
};
</script>

<style lang="scss" scoped>

@import "FooterContact.scss";


</style>