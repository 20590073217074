<template>
	<nav id="nav" class="nav">
		<div class="nav__header">
			<div class="nav__logo">
				<a href="/">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.13 123.46">
						<g id="Layer_2" data-name="Layer 2">
							<g id="レイヤー_1" data-name="レイヤー 1">
								<path
									class="cls-1"
									d="M58.65,111a.86.86,0,0,1,.65.28.88.88,0,0,1,.27.65.92.92,0,0,1-1.84,0,.88.88,0,0,1,.27-.65A.86.86,0,0,1,58.65,111Zm-.56,3.58h1.12v8.68H58.09Z"
								/>
								<path
									class="cls-1"
									d="M62.8,114.55h1.12v1.56a4.62,4.62,0,0,1,1.48-1.34,3.64,3.64,0,0,1,1.78-.44,3.06,3.06,0,0,1,1.72.49A3,3,0,0,1,70,116.15a7,7,0,0,1,.36,2.61v4.47H69.26v-4.14a9.46,9.46,0,0,0-.13-2,2.18,2.18,0,0,0-.74-1.3,2.26,2.26,0,0,0-1.44-.43,2.73,2.73,0,0,0-1.81.67,3.09,3.09,0,0,0-1.06,1.66,11.27,11.27,0,0,0-.16,2.36v3.18H62.8Z"
								/>
								<path
									class="cls-1"
									d="M84,114.33a4.3,4.3,0,0,1,3.33,1.45,4.66,4.66,0,0,1-.07,6.32,4.59,4.59,0,0,1-6.52,0,4.65,4.65,0,0,1-.07-6.31A4.27,4.27,0,0,1,84,114.33Zm0,1.09a3.22,3.22,0,0,0-2.4,1A3.6,3.6,0,0,0,81,120.7,3.15,3.15,0,0,0,82.27,122a3.36,3.36,0,0,0,1.71.45,3.3,3.3,0,0,0,1.7-.45,3.24,3.24,0,0,0,1.24-1.25,3.6,3.6,0,0,0,.45-1.76,3.44,3.44,0,0,0-1-2.49A3.22,3.22,0,0,0,84,115.42Z"
								/>
								<path
									class="cls-1"
									d="M91.51,114.55h1.12v1.56a4.55,4.55,0,0,1,1.49-1.34,3.58,3.58,0,0,1,1.77-.44,3.1,3.1,0,0,1,1.73.49,3,3,0,0,1,1.11,1.33,7,7,0,0,1,.36,2.61v4.47H98v-4.14a10.49,10.49,0,0,0-.12-2,2.19,2.19,0,0,0-.75-1.3,2.23,2.23,0,0,0-1.43-.43,2.73,2.73,0,0,0-1.82.67,3.09,3.09,0,0,0-1.06,1.66,11.27,11.27,0,0,0-.16,2.36v3.18H91.51Z"
								/>
								<path
									class="cls-1"
									d="M110.06,120.35l1,.5a5.29,5.29,0,0,1-1.08,1.47,4.39,4.39,0,0,1-1.36.85,4.88,4.88,0,0,1-1.72.29,4.14,4.14,0,0,1-3.32-1.4,4.76,4.76,0,0,1-.19-6.09,4.15,4.15,0,0,1,3.45-1.64,4.27,4.27,0,0,1,3.54,1.68,4.68,4.68,0,0,1,1,3h-7.81a3.49,3.49,0,0,0,1,2.48,3.05,3.05,0,0,0,2.3,1,3.83,3.83,0,0,0,1.3-.23,3.45,3.45,0,0,0,1.06-.62A4.85,4.85,0,0,0,110.06,120.35Zm0-2.34a3.6,3.6,0,0,0-.65-1.42,3,3,0,0,0-1.13-.87,3.58,3.58,0,0,0-1.48-.32,3.15,3.15,0,0,0-2.19.82,3.73,3.73,0,0,0-1,1.79Z"
								/>
								<path
									class="cls-1"
									d="M120.06,114.55h1.14v1.27a4,4,0,0,1,1.08-1.12,2,2,0,0,1,2.17-.08l-.58.94a1.59,1.59,0,0,0-.59-.15,1.65,1.65,0,0,0-1.08.46,2.87,2.87,0,0,0-.79,1.43,13.44,13.44,0,0,0-.21,3v2.93h-1.14Z"
								/>
								<path
									class="cls-1"
									d="M130.8,114.33a4.3,4.3,0,0,1,3.33,1.45,4.66,4.66,0,0,1-.07,6.32,4.59,4.59,0,0,1-6.52,0,4.65,4.65,0,0,1-.07-6.31A4.27,4.27,0,0,1,130.8,114.33Zm0,1.09a3.22,3.22,0,0,0-2.4,1,3.44,3.44,0,0,0-1,2.49,3.51,3.51,0,0,0,.46,1.76,3.15,3.15,0,0,0,1.23,1.25,3.36,3.36,0,0,0,1.71.45,3.33,3.33,0,0,0,1.7-.45,3.24,3.24,0,0,0,1.24-1.25,3.6,3.6,0,0,0,.45-1.76,3.44,3.44,0,0,0-1-2.49A3.22,3.22,0,0,0,130.8,115.42Z"
								/>
								<path
									class="cls-1"
									d="M142.13,114.33a4.29,4.29,0,0,1,3.32,1.45,4.66,4.66,0,0,1-.07,6.32,4.59,4.59,0,0,1-6.52,0,4.65,4.65,0,0,1-.07-6.31A4.28,4.28,0,0,1,142.13,114.33Zm0,1.09a3.19,3.19,0,0,0-2.39,1,3.45,3.45,0,0,0-1,2.49,3.61,3.61,0,0,0,.46,1.76,3.24,3.24,0,0,0,1.24,1.25,3.3,3.3,0,0,0,1.7.45,3.36,3.36,0,0,0,1.71-.45,3.15,3.15,0,0,0,1.23-1.25,3.51,3.51,0,0,0,.46-1.76,3.45,3.45,0,0,0-1-2.49A3.2,3.2,0,0,0,142.12,115.42Z"
								/>
								<path
									class="cls-1"
									d="M153.57,111.2v1.09a2.86,2.86,0,0,0-1-.26,1,1,0,0,0-.54.13.54.54,0,0,0-.27.3,4,4,0,0,0-.06.92v1.17h1.81v1h-1.81v7.71h-1.12v-7.71h-1.14v-1h1.14v-1.36a3.53,3.53,0,0,1,.18-1.33,1.28,1.28,0,0,1,.58-.64,1.91,1.91,0,0,1,1-.25A4.69,4.69,0,0,1,153.57,111.2Z"
								/>
								<polygon
									class="cls-1"
									points="211.05 50.68 110.02 10.15 128.38 2.78 127.27 0 106 8.53 84.72 0 83.61 2.78 101.97 10.15 0.94 50.68 2.06 53.47 106 11.77 209.93 53.47 211.05 50.68"
								/>
								<path
									class="cls-1"
									d="M87.94,98.14a15.74,15.74,0,0,1-.14-31.47h.13a15.75,15.75,0,0,1,14.51,9.61l-2.76,1.17a12.72,12.72,0,0,0-11.74-7.78h-.11a12.74,12.74,0,0,0,.11,25.47h0a12.75,12.75,0,0,0,11.74-7.78l2.76,1.17a15.43,15.43,0,0,1-3.37,5,15.6,15.6,0,0,1-11,4.61h-.14Z"
								/>
								<path
									class="cls-1"
									d="M45.64,86.25,65.7,79a1.5,1.5,0,0,0,.85-.77,1.53,1.53,0,0,0,0-1.15A15.79,15.79,0,0,0,51.84,66.67a16.29,16.29,0,0,0-5.38.94h0a15.73,15.73,0,0,0,5.4,30.51H52a15.79,15.79,0,0,0,14.36-9.61l-2.77-1.17a12.78,12.78,0,0,1-6.78,6.78,12.51,12.51,0,0,1-4.95,1h0a12.74,12.74,0,0,1-4.34-24.71h0a12.76,12.76,0,0,1,4.32-.76h0a12.79,12.79,0,0,1,11.35,7L44.61,83.44Z"
								/>
								<path
									class="cls-1"
									d="M205.29,91.41a12.7,12.7,0,0,1-9,3.73h0a12.74,12.74,0,0,1-4.35-24.71h0a12.82,12.82,0,0,1,4.33-.76h0a12.79,12.79,0,0,1,11.35,7l-18.58,6.76,1,2.81L210.15,79A1.49,1.49,0,0,0,211,77a15.79,15.79,0,0,0-14.76-10.36,16.32,16.32,0,0,0-5.37.94h0a15.74,15.74,0,0,0,5.41,30.51h.14a15.6,15.6,0,0,0,11-4.61,15.59,15.59,0,0,0,3.37-5L208,87.36A12.64,12.64,0,0,1,205.29,91.41Z"
								/>
								<path
									class="cls-1"
									d="M124.08,98.14a15.74,15.74,0,1,1,15.74-15.73A15.75,15.75,0,0,1,124.08,98.14Zm0-28.47a12.74,12.74,0,1,0,12.74,12.74A12.75,12.75,0,0,0,124.08,69.67Z"
								/>
								<path
									class="cls-1"
									d="M28.47,52.07V82.41h0a12.74,12.74,0,1,1-16.6-12.14l.54-.14a12.8,12.8,0,0,1,8.28.54,12.64,12.64,0,0,1,4,2.73l2.12-2.12a15.65,15.65,0,0,0-11.12-4.6h0a15.67,15.67,0,0,0-4.14.57l-.71.2c-.43.14-.86.29-1.28.47h0a15.74,15.74,0,1,0,21.86,14.5h0V52.07Z"
								/>
								<path
									class="cls-1"
									d="M172.89,52.07V82.41h0a12.74,12.74,0,1,1-16.6-12.14l.53-.14a12.82,12.82,0,0,1,8.29.54,12.64,12.64,0,0,1,4,2.73l2.12-2.12a15.65,15.65,0,0,0-11.13-4.6h0a15.66,15.66,0,0,0-4.13.57l-.72.2c-.43.14-.86.29-1.27.47h0a15.74,15.74,0,1,0,21.86,14.5h0V52.07Z"
								/>
							</g>
						</g>
					</svg>
				</a>
			</div>

			<div class="nav__links nav__links--mobile">
				<ul class="nav__menu__list">
					<li class="nav__menu__items nav__menu__items--mobile">
						<div class="nav__menu__icon" @click="emitToParent()">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14.53">
								<g id="Layer_2" data-name="Layer 2">
									<g id="Layer_3" data-name="Layer 3">
										<path
											class="cls-1"
											d="M19.34,1.32H.66A.66.66,0,0,1,.66,0H19.34a.66.66,0,1,1,0,1.32Z"
										/>
										<path
											class="cls-1"
											d="M19.34,7.92h-14a.66.66,0,0,1,0-1.32h14a.66.66,0,1,1,0,1.32Z"
										/>
										<path
											class="cls-1"
											d="M19.34,14.53H10a.66.66,0,0,1,0-1.32h9.34a.66.66,0,1,1,0,1.32Z"
										/>
									</g>
								</g>
							</svg>
						</div>
					</li>
				</ul>
			</div>
			<div class="nav__links nav__links--desktop">
				<ul class="nav__menu__list">
					<li class="nav__menu__items">
						<router-link to="/">Home</router-link>
					</li>
					<li class="nav__menu__items">
						<router-link to="/projects">Projects</router-link>
					</li>
					<li class="nav__menu__items">
						<router-link to="/services" >Services</router-link>
					</li>
					<li class="nav__menu__items">
						<router-link to="/company">Company</router-link>
					</li>
					<li class="nav__menu__items">
						<router-link to="/contact">Contact</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
import Button from "./Button";
import { isMobile } from "mobile-device-detect";
import NavBarDrawer from "./NavBarDrawer";
export default {
	components: {
		Button,
		NavBarDrawer,
	},
	props: {
		isOpen: Boolean,
	},
	data() {
		return {
			isDropdownOpen: false,
			openNav: false,
			menuIcon: require("../assets/images/icons/svg/menu-8.svg"),
		};
	},

	methods: {
		emitToParent() {
			this.openNav = !this.isOpen;
			this.$emit("drawerState", this.openNav);
		},
	},
	computed: {
		mobileCheck() {
			return isMobile;
		},
	},
};
</script>

<style lang="scss" scoped>

@import "NavBar.scss";

</style> 