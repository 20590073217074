<template>
	<div class="projectdetails">
		<ProjectDetailsContent />
	</div>
</template>

<script>
import ProjectDetailsContent from "../contents/ProjectDetailsContent";

export default {
	components: {
		ProjectDetailsContent,
	},
};
</script>

<style>
</style>