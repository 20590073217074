<template>
	<div class="nav__button">
		<!-- <lottie
		class="nav__button"
		:options="defaultOptions"
		:height="40"
		:width="40"
		v-on:animCreated="handleAnimation"
	/> -->
		<img :src="icon" alt="menu" srcset="" width="40" height="40" />
	</div>
</template>

<script>
	import Lottie from "../util/lottie";
	// import * as menuIcon from "../assets/images/icons/menu.json";
	export default {
		components: {
			lottie: Lottie,
		},
		props: {
			isOpen: Boolean,
			icon: Object,
			iconName: String,
		},
		data() {
			return {
				// isOpen: false,
				defaultOptions: {
					animationData: this.icon,
					loop: false,
					autoplay: false,
					renderer: "canvas",
				},
			};
		},

		methods: {
			handleAnimation(anim) {
				this.anim = anim;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "Button.scss";
</style>
