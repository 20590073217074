<template>
	<div class="home home__content">
		<section class="banner">
			<div class="banner__text wrapper">
				<p class="banner__text--underline">
					We are decode
				</p>

				<h1 class="banner__text--header">
					クリエイティブ <br />×<br />テクノロジー
				</h1>
				<p class="banner__text--subheader">
					訴求力の高いサイトを構築します。
				</p>
			</div>
		</section>
		<section class="home__bgbanner">
			<HomeBackgroundBanner />
		</section>
		<section class="home__decode">
			<HomeDescription />
		</section>

		<section class="home__featured wrapper">
			<div class="home__featured__text"><h1>Featured Works</h1></div>
			<div class="home__featured__cards">
				<CardList :featuredOnly="featuredOnly" />
			</div>

			<div class="home__cta">
				<router-link to="/projects" class="home__cta__button">
					<span class="home__cta__flex">
						<span class="home__cta__text">See More Projects</span>
					</span>
				</router-link>
			</div>
		</section>

		<!-- add link to services page -->
	</div>
</template>

<script>
	import CardList from "../components/CardList.vue";

	import Banner from "../components/Banner.vue";
	import HomeBackgroundBanner from "../components/HomeBackgroundBanner.vue";
	import HomeDescription from "../components/HomeDescription.vue";

	export default {
		components: { CardList, Banner, HomeBackgroundBanner, HomeDescription },
		data() {
			return {
				featuredOnly: true,
				transitionTrigger: true,
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import "HomeContent.scss";
</style>
