<template>
	<div class="card">
		<router-link :to="`/projects/${item.id}`">
			<div class="card__category" v-html="categoryList"></div>
			<div class="card__thumbnail">
				<img :src="item.bgImg.url" :alt="item.title" />
			</div>
			<div class="card__info">
				<div class="card__title">{{ item.title }}</div>
				<div class="card__date">{{ formatTime }}</div>
			</div>
		</router-link>
	</div>
</template>

<script>
	import moment from "moment";
	export default {
		name: "Card",
		props: {
			item: Object,
		},
		data() {
			return {
				category: "",
			};
		},

		computed: {
			categoryList() {
				return (this.category = this.item.category.join(" &#183; "));
			},
			formatTime() {
				return moment(this.item.date).format("YYYY");
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "Card.scss";
</style>
