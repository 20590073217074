<template>
	<div>
		<HeaderContent
			@drawerState="updatedDrawerState($event)"
			:isOpen="openDrawer"
		/>
		<NavBarDrawer
			:isOpen="openDrawer"
			@itemClicked="updatedDrawerState($event)"
		/>

		<router-view v-slot="{ Component, route }" appear>
			<transition name="route" mode="out-in">
				<component :is="Component" :key="route.path" />
			</transition>
		</router-view>
		<FooterContent />
	</div>
</template>

<script>
	// component
	import FooterContent from "./contents/FooterContent";
	import HeaderContent from "./contents/HeaderContent.vue";
	import NavBarDrawer from "./components/NavBarDrawer.vue";
	// library
	import AOS from "aos";
	export default {
		components: {
			FooterContent,
			HeaderContent,
			NavBarDrawer,
		},

		created() {
			AOS.init();
		},

		data() {
			return {
				openDrawer: false,
			};
		},
		methods: {
			updatedDrawerState(value) {
				this.openDrawer = value;
			},
		},
		computed: {},
	};
</script>

<style lang="scss" scoped>
	.tint {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		background: #00000080;
		// display: block;
		z-index: 7000;
	}

	.show {
		display: block;
	}

	.hidden {
		display: none;
	}

	.route-enter-from {
		opacity: 0;
	}
	.route-enter-to {
		opacity: 1;
	}
	.route-enter-active {
		transition: all 0.5s ease;
	}
	.route-leave-from {
		opacity: 1;
	}
	.route-leave-to {
		opacity: 0;
	}
	.route-leave-active {
		transition: all 0.5s ease;
	}
</style>
