<template>
	<div class="card">
		<div
			class="card__category card__category--skeleton"
			v-html="categoryList"
		></div>

		<div class="card__thumbnail">
			<img :src="item.thumbnail.url" :alt="item.title" />
		</div>
		<div class="card__info card__info--skeleton">
			<div class="card__title">{{ item.title }}</div>
			<div class="card__date">{{ formatTime }}</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	export default {
		name: "Card",
		props: {
			item: Object,
		},
		data() {
			return {};
		},
		mounted() {},
		computed: {
			categoryList() {
				return this.item.category.join(" &#183; ");
			},
			formatTime() {
				return moment(this.item.date).format("YYYY");
			},
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	@import "CardSkeleton.scss";
</style>
