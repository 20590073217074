<template>
	<div class="details">
		<div
			class="details__banner"
			:style="{ 'background-image': `url(${bannerImg})` }"
		>
			<div class="tint"></div>
			<Banner
				:underlineText="category"
				:headerText="project.title"
				:subHeader="project.client"
				:color="color"
			/>
		</div>
		<section class="details__info">
			<div class="details__board">
				<div class="details__overview">
					<div class="details__overview--underline">
						<p>Overview</p>
					</div>
					<div class="details__overview--title">
						<div
							class="details__overview--formatted"
							v-html="project.overview"
						></div>
						<!-- <p>{{ project.overview }}</p> -->
					</div>
				</div>
				<div class="details__project">
					<div class="details__project--underline">
						<p>Title</p>
					</div>
					<div class="details__project--text">
						<p>{{ project.title }}</p>
					</div>
				</div>
				<div class="details__category">
					<div class="details__category--underline">
						<p>Category</p>
					</div>
					<div class="details__category--text">
						<p v-html="category"></p>
					</div>
				</div>
				<div class="details__client">
					<div class="details__client--underline">
						<p>Client</p>
					</div>
					<div class="details__client--text">
						<p>{{ project.client }}</p>
					</div>
				</div>
				<div class="details__date">
					<div class="details__date--underline">
						<p>Date</p>
					</div>
					<div class="details__date--text">
						<p>{{ formatTime }}</p>
					</div>
				</div>
				<div class="details__team" v-if="project.team !== ''">
					<div class="details__team--underline">
						<p>team</p>
					</div>

					<div class="details__team--list">
						<!-- TODO: lood inside the project.team object for dynamic code block -->
						<div v-for="member in project.team" :key="member.id">
							<div v-for="(name, position) in member" :key="position">
								<div v-if="name !== 'team'">
									<div class="details__teamlist">
										<div class="details__teamlist__text">
											<p v-html="formatPosition(position)"></p>
										</div>
										<div class="details__teamlist__members">
											<div
												class="details__teamlist__items"
												v-html="formatValue(name)"
											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="details__external">
				<a
					:href="project.link"
					class="details__cta"
					v-if="project.link"
					target="_blank"
					rel="noopener noreferrer"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_3" data-name="Layer 3">
								<path
									class="svg-link"
									d="M11.25,13.7,7.58,17.38c-1.82,1.81-3.76,1.95-5.34.38s-1.43-3.52.38-5.34L6.3,8.75a.59.59,0,0,0,0-.82.57.57,0,0,0-.82,0L1.81,11.6c-2.71,2.71-2,5.38-.38,7s4.26,2.33,7-.38l3.67-3.67a.57.57,0,0,0,0-.82A.59.59,0,0,0,11.25,13.7Z"
								/>
								<path
									class="svg-link"
									d="M11.6,1.81,7.93,5.48a.57.57,0,0,0,0,.82.59.59,0,0,0,.82,0l3.67-3.68C14.24.81,16.18.67,17.76,2.24s1.43,3.52-.38,5.34L13.7,11.25a.59.59,0,0,0,0,.82.57.57,0,0,0,.82,0L18.19,8.4c2.71-2.71,2-5.38.38-7S14.31-.9,11.6,1.81Z"
								/>
								<path
									class="svg-link"
									d="M5.51,14.49a.57.57,0,0,0,.82,0l8.16-8.16a.58.58,0,0,0-.82-.82L5.51,13.67A.57.57,0,0,0,5.51,14.49Z"
								/>
							</g>
						</g>
					</svg>
				</a>
				<a class="details__cta details__cta--disable" v-else>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_3" data-name="Layer 3">
								<path
									class="svg-broken-link"
									d="M10.44,14.52,7.58,17.38c-1.82,1.82-3.76,2-5.34.38a3.36,3.36,0,0,1-1.08-2.42,4.28,4.28,0,0,1,1.46-2.92L5.48,9.56a.58.58,0,0,0,0-.81.57.57,0,0,0-.82,0L1.8,11.6A5.52,5.52,0,0,0,0,15.33a4.53,4.53,0,0,0,1.42,3.25A4.58,4.58,0,0,0,4.64,20,5.33,5.33,0,0,0,8.4,18.2l2.85-2.86a.57.57,0,0,0,0-.82A.58.58,0,0,0,10.44,14.52Z"
								/>
								<path
									class="svg-broken-link"
									d="M5.51,14.49a.54.54,0,0,0,.41.17.54.54,0,0,0,.4-.17l2-2a.58.58,0,1,0-.82-.82l-2,2A.56.56,0,0,0,5.51,14.49Z"
								/>
								<path
									class="svg-broken-link"
									d="M13.73,14.85a.57.57,0,0,0-.57.58v2.39a.57.57,0,0,0,.57.58.58.58,0,0,0,.58-.58V15.43A.58.58,0,0,0,13.73,14.85Z"
								/>
								<path
									class="svg-broken-link"
									d="M2.18,6.84H4.57a.57.57,0,0,0,.58-.57.58.58,0,0,0-.58-.58H2.18a.58.58,0,0,0-.58.58A.57.57,0,0,0,2.18,6.84Z"
								/>
								<path
									class="svg-broken-link"
									d="M15.34,11.25,18.2,8.4A5.52,5.52,0,0,0,20,4.67a4.53,4.53,0,0,0-1.42-3.25A4.52,4.52,0,0,0,15.38,0h-.05A5.52,5.52,0,0,0,11.6,1.8L8.75,4.66a.57.57,0,0,0,0,.82.58.58,0,0,0,.81,0l2.86-2.86a4.28,4.28,0,0,1,2.92-1.46,3.35,3.35,0,0,1,2.42,1.08,3.36,3.36,0,0,1,1.08,2.42,4.28,4.28,0,0,1-1.46,2.92l-2.86,2.86a.58.58,0,0,0,0,.81.58.58,0,0,0,.82,0Z"
								/>
								<path
									class="svg-broken-link"
									d="M14.49,5.51a.56.56,0,0,0-.81,0l-2,2a.59.59,0,0,0,0,.82.6.6,0,0,0,.41.17.58.58,0,0,0,.41-.17l2-2A.56.56,0,0,0,14.49,5.51Z"
								/>
								<path
									class="svg-broken-link"
									d="M6.27,5.15a.57.57,0,0,0,.57-.58V2.18a.57.57,0,0,0-.57-.58.58.58,0,0,0-.58.58V4.57A.58.58,0,0,0,6.27,5.15Z"
								/>
								<path
									class="svg-broken-link"
									d="M17.82,13.16H15.43a.57.57,0,0,0-.58.57.58.58,0,0,0,.58.58h2.39a.58.58,0,0,0,.58-.58A.57.57,0,0,0,17.82,13.16Z"
								/>
							</g>
						</g>
					</svg>
				</a>
			</div>
		</section>
		<section class="details__moodboard">
			<div v-html="moodboard" class="details__boardcontainer"></div>
		</section>
		<!-- TODO: add section for related/other projects -->
	</div>
</template>

<script>
import Banner from "../components/Banner";
import CTA from "../components/CTA.vue";
import CardList from "../components/CardList.vue";
import moment from "moment";
import axios from "axios";

export default {
	components: { Banner, CTA, CardList },
	data() {
		return {
			color: "white",
			ctaWidth: 70,
			flexType: "row",
			project: {},
			category: "",
			bannerImg: "",
			moodboard: "",
		};
	},
	mounted() {
		let url = `https://decode.microcms.io/api/v1/projects/${this.$route.params.id}`;

		let headers = {
			"X-API-KEY": "3b923ffb-7c8f-45b1-b0fb-f5a00647b288",
		};
		this.getData(url, headers);
	},
	methods: {
		getData(url, headers) {
			axios({
				method: "get",
				url,
				headers,
			})
				.then((res) => {
					// console.log(res.data);
					this.project = res.data;
					this.category = this.project.category.join(" &#183; ");
					this.moodboard = res.data.board;
					if (this.project.bannerImg) {
						this.bannerImg = this.project.bannerImg.url;
					} else {
						this.bannerImg = "#fff";
					}
				})
				.catch((err) => {
					this.$router.push({ name: "NotFound" });
				});
		},
		formatValue(val) {
			return val.split(",").join(",<br/>");
		},
		formatPosition(pos) {
			return pos.replace(/_/g, " ") + ":";
		},
	},

	computed: {
		formatTime() {
			return moment(this.project.date).format("MM/YYYY");
		},
		bgImage() {
			if (this.bannerImg) {
				return `url("${this.bannerImg}")`;
			} else {
				return "#fff";
			}
		},
		nl2br() {},
	},
};
</script>

<style lang="scss" scoped>
@import "ProjectDetailsContent.scss";
</style>