<template>
	<div class="homebg">
		<div class="homebg__img">
			<!-- <div class="homebg__img--design">
				<img src="../assets/images/banner/design@2x.png" alt="" />
			</div>
			<div class="homebg__img--development">
				<img src="../assets/images/banner/development@2x.png" alt="" />
			</div>
			<div class="homebg__img--deploy">
				<img src="../assets/images/banner/deploy@2x.png" alt="" />
			</div> -->
			<div class="homebg__img--combi">
				<img src="../assets/images/banner/combi@2x.png" alt="banner" />
			</div>

			<!-- <div class="homebg__rect">
			<svg>
				<transition name="slide-up" appear>
					<rect />
				</transition>
			</svg>
		</div> -->
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	// .homebg {
	// 	position: relative;
	// 	width: 1195px;
	// 	top: 0%;
	// 	left: 50%;
	// 	transform: translateX(-50%);

	// 	&__img {
	// 		&--design {
	// 			position: absolute;
	// 			top: 0;
	// 			left: 0;
	// 			max-width: 1062px;
	// 			width: 100%;
	// 			max-height: 581px;
	// 			height: 100%;
	// 		}

	// 		&--development {
	// 			position: absolute;
	// 			top: 70px;
	// 			left: 70px;
	// 			max-width: 1062px;
	// 			width: 100%;
	// 			max-height: 581px;
	// 			height: 100%;
	// 		}
	// 		&--deploy {
	// 			position: absolute;
	// 			top: 140px;
	// 			left: 140px;
	// 			max-width: 1062px;
	// 			width: 100%;
	// 			max-height: 581px;
	// 			height: 100%;
	// 		}
	// 	}
	// 	img {
	// 		width: 100%;
	// 		height: auto;
	// 		border-radius: 5px;
	// 	}
	// }
	.homebg {
		// max-width: 1196px;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;

		background: linear-gradient(0deg, #1f1f1f 50%, #f6f6f6 50%);

		&__img {
			&--combi {
				margin: 0 auto;
				max-width: 1196px;
				width: 100%;
			}
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 1024px) {
		.homebg {
			&__img {
				&--combi {
					margin: 0 auto;
					max-width: 896px;

					padding: 0 20px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.homebg {
			&__img {
				&--combi {
					margin: 0 auto;
					max-width: none;
					width: auto;
					padding: 0 20px;
				}
			}
		}
	}
	@media screen and (max-width: 425px) {
		.homebg {
			&__img {
				&--combi {
					margin: 0 auto;
					max-width: none;
					width: auto;
					padding: 0 20px;
				}
			}
		}
	}
</style>
