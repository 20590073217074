<template>
	<header class="header">
		<div class="header__content wrapper">
			<NavBar @drawerState="updatedDrawerState($event)" :isOpen="isOpen" />
		</div>
		<!-- <NavBarDrawer /> -->
	</header>
</template>

<script>
import NavBar from "@/components/NavBar";
import NavBarDrawer from "@/components/NavBarDrawer";

export default {
	components: {
		NavBar,
		NavBarDrawer,
	},
	props: {
		isOpen: Boolean,
	},
	methods: {
		updatedDrawerState(value) {
			this.$emit("drawerState", value);
		},
	},
};
</script>

<style lang="scss" scoped>


@import "HeaderContent.scss";



</style>