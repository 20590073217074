import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import ProjectDetails from "../views/ProjectDetails.vue";
const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/company",
		name: "Company",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "company" */ "../views/Company.vue"),
	},
	{
		path: "/projects",
		name: "Projects",

		component: () =>
			import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
		children: [
			{
				path: ":id",
				component: ProjectDetails,
			},
		],
	},
	// {
	// 	path: "/projects/:id",
	// 	name: "ProjectsDetails",
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "projectsdetails" */ "../views/ProjectDetails.vue"
	// 		),
	// },
	{
		path: "/contact",
		name: "Contact",
		component: () =>
			import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
	},
	{
		path: "/services",
		name: "Services",
		component: () =>
			import(/* webpackChunkName: "services" */ "../views/Services.vue"),
	},
	{
		path: "/:catchAll(.*)*",
		name: "NotFound",
		component: () =>
			import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// hack solution for transition before scroll
		// Issue: changing routes trigger scroll before animation
		// https://github.com/quasarframework/quasar/issues/1466#issue-290498826

		// In order to prevent the browser's default behavior
		if ("scrollRestoration" in history) {
			history.scrollRestoration = "manual";
		}
		// use left and top instead of x and y
		let position = { left: 0, top: 0 };
		if (savedPosition) {
			position = savedPosition;
		}
		// delay for the scroll trigger
		// this helps the illusion of transition before scroll
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(position);
			}, 500);
		});
	},
});

export default router;
