<template>
	<div class="drawer" :class="openDrawer">
		<div class="drawer__container">
			<div class="drawer__flex">
				<div class="drawer__menu">
					<div class="drawer__menu__text">
						<h3 class="drawer__menu__text--underline">Menu</h3>
					</div>
					<ul class="drawer__menu__list" @click="itemClicked()">
						<li class="drawer__menu__items">
							<router-link to="/">Home</router-link>
						</li>
						<li class="drawer__menu__items">
							<router-link to="/projects">Projects</router-link>
						</li>
						<li class="drawer__menu__items">
							<router-link to="/services">Services</router-link>
						</li>
						<li class="drawer__menu__items">
							<router-link to="/company">Company</router-link>
						</li>
						<li class="drawer__menu__items">
							<router-link to="/contact">Contact</router-link>
						</li>
					</ul>
				</div>
				<!-- <div class="drawer__socials">
					<div class="drawer__socials__text">
						<h3 class="drawer__socials__text--underline">Socials</h3>
					</div>
					<ul class="drawer__socials__list">
						<li class="drawer__socials__items">Facebook</li>
						<li class="drawer__socials__items">Twitter</li>
						<li class="drawer__socials__items">Instagram</li>
					</ul>
				</div> -->
			</div>
			<!-- <div class="drawer__contact">
				<div class="drawer__contact__text">
					<h3 class="drawer__contact__text--underline">Say Hi!</h3>
				</div>
				<ul class="drawer__contact__list">
					<li class="drawer__contact__items">info@decodetokyo.com</li>
				</ul>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isOpen: Boolean,
	},
	data() {
		return {
			openNav: false,
		};
	},
	methods: {
		itemClicked() {
			this.openNav = !this.isOpen;
			// console.log(this.openNav);
			this.$emit("itemClicked", this.openNav);
		},
	},
	computed: {
		openDrawer() {
			return this.isOpen ? "" : "hidden";
		},
	},
};
</script>

<style lang="scss" scoped>
@import "NavBarDrawer.scss";
</style>