<template>
	<div class="homedescription">
		<div class="homedescription__list">
			<div class="homedescription__listitem">
				<div class="homedescription__icon">
					<!-- <img src="../assets/images/icons/svg/design_1.svg" alt="" /> -->
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_3" data-name="Layer 3">
								<path
									class="cls-1"
									d="M10.48,7.11h2.41V9.52h.95V6.63a.47.47,0,0,0-.47-.47H10.48Z"
								/>
								<path
									class="cls-1"
									d="M7.11,10.48h-1v2.89a.47.47,0,0,0,.47.47H9.52v-.95H7.11Z"
								/>
								<path
									class="cls-1"
									d="M19.52,0H6.63a.47.47,0,0,0-.47.48V6.16H.48A.47.47,0,0,0,0,6.63V19.52A.47.47,0,0,0,.48,20H13.37a.47.47,0,0,0,.47-.48V13.84h5.68a.47.47,0,0,0,.48-.47V.48A.47.47,0,0,0,19.52,0Zm-.47,12.89H13.84V10.48h-.95v2.41H10.48v.95h2.41v5.21H1V7.11H6.16V9.52h1V7.11H9.52v-1H7.11V1H19.05Z"
								/>
							</g>
						</g>
					</svg>
				</div>
				<div class="homedescription__text">
					<div class="homedescription__text--title">
						<h1>DE:sign</h1>
					</div>
					<div class="homedescription__text--desc">
						<p>
							様々なクリエイティブの領域で経験豊富なデザイナーが、ユーザーのニーズに応じた設計（UI/UXデザイン）、ビジュアルを駆使して魅力を最大限に引き出します。
						</p>
					</div>
				</div>
			</div>
			<div class="homedescription__listitem">
				<div class="homedescription__icon">
					<!-- <img src="../assets/images/icons/svg/code.svg" alt="" /> -->
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13.6">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_3" data-name="Layer 3">
								<path
									class="cls-1"
									d="M5.24,12a.49.49,0,0,1-.34-.14L.14,7.14a.46.46,0,0,1,0-.67L4.9,1.7a.48.48,0,0,1,.67,0,.48.48,0,0,1,0,.68L1.15,6.8l4.42,4.43a.46.46,0,0,1,0,.67A.47.47,0,0,1,5.24,12Z"
								/>
								<path
									class="cls-1"
									d="M14.76,12a.47.47,0,0,1-.33-.14.46.46,0,0,1,0-.67L18.85,6.8,14.43,2.38a.48.48,0,0,1,0-.68.48.48,0,0,1,.67,0l4.76,4.77a.46.46,0,0,1,0,.67L15.1,11.9A.49.49,0,0,1,14.76,12Z"
								/>
								<path
									class="cls-1"
									d="M7.69,13.6a.41.41,0,0,1-.16,0A.48.48,0,0,1,7.24,13L11.86.31A.48.48,0,0,1,12.47,0a.48.48,0,0,1,.29.61L8.14,13.29A.49.49,0,0,1,7.69,13.6Z"
								/>
							</g>
						</g>
					</svg>
				</div>
				<div class="homedescription__text">
					<div class="homedescription__text--title">
						<h1>CO:de</h1>
					</div>
					<div class="homedescription__text--desc">
						<p>
							デザインを忠実に再現し、運用まで意識した汎用性の高いコードを書くことに最善を尽くします。また、最新の技術を常に取り入れお客様への価値還元に繋げています。
						</p>
					</div>
				</div>
			</div>
			<div class="homedescription__listitem">
				<div class="homedescription__icon">
					<!-- <img src="../assets/images/icons/svg/develop.svg" alt="" /> -->
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_3" data-name="Layer 3">
								<path
									class="cls-1"
									d="M14.72,10.52a2.67,2.67,0,1,0,0-1h-4.2V3.19h4.2a2.67,2.67,0,1,0,0-1H10a.52.52,0,0,0-.52.52h0V9.48H5.28a2.67,2.67,0,1,0,0,1h4.2v6.81a.52.52,0,0,0,.52.52h4.72a2.66,2.66,0,1,0,0-1h-4.2V10.52Zm2.61-2.15a1.63,1.63,0,1,1-1.54,2.15,1.55,1.55,0,0,1,0-1A1.64,1.64,0,0,1,17.33,8.37ZM2.67,11.63A1.63,1.63,0,1,1,4.21,9.48a1.55,1.55,0,0,1,0,1A1.64,1.64,0,0,1,2.67,11.63ZM17.33,15.7a1.63,1.63,0,1,1-1.54,2.15,1.77,1.77,0,0,1-.09-.52,1.83,1.83,0,0,1,.09-.52A1.64,1.64,0,0,1,17.33,15.7ZM17.33,1a1.63,1.63,0,1,1-1.54,2.15,1.83,1.83,0,0,1-.09-.52,1.77,1.77,0,0,1,.09-.52A1.63,1.63,0,0,1,17.33,1Z"
								/>
							</g>
						</g>
					</svg>
				</div>
				<div class="homedescription__text">
					<div class="homedescription__text--title">
						<h1>DE:velop</h1>
					</div>
					<div class="homedescription__text--desc">
						<p>
							お客様の要望を実現するため、CMSの導入・構築や、スクラッチでの開発が可能です。サイト構築以降もお客様自身で更新ができる仕組み作りまで行います。
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HomeDescription",
	};
</script>

<style lang="scss" scoped>
	.cls-1 {
		fill: #ffffff;
	}
	.homedescription {
		color: #fff;

		width: 100%;
		// min-height: 100vh;
		background: #1f1f1f;
		display: flex;

		// align-items: flex-end;

		&__icon {
			width: 50px;
			height: 50px;
			display: flex;
		}
		&__listitem {
			padding: 10px;
		}

		&__list {
			padding: 160px 10px;
			display: flex;
			flex-wrap: nowrap;
			max-width: 1440px;
			width: 100%;
			margin: 0 auto;

			// padding-bottom: 160px;
			justify-content: space-between;
		}

		&__text {
			&--title {
				font-family: "Hiragino Kaku Gothic Pro", sans-serif;
				font-weight: 800;
				font-size: 2em;
				margin-top: 50px;
				margin-bottom: 18px;
				letter-spacing: 1.5px;
			}
			&--desc {
				font-size: 0.875em;
				max-width: 364px;
				line-height: 1.6;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.homedescription {
			font-size: 16px;
			&__icon {
				width: 40px;
				height: 40px;
				display: flex;
			}
			&__list {
				padding: 130px 20px;
				gap: 60px;
				flex-wrap: nowrap;
			}
			&__text {
				&--title {
					margin-top: 40px;
					margin-bottom: 18px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.homedescription {
			font-size: 10px;
			&__icon {
				width: 40px;
				height: 40px;
				display: flex;
			}
			&__list {
				padding: 100px 20px;
				gap: 60px;
				flex-wrap: nowrap;
			}
			&__text {
				&--title {
					margin-top: 20px;
					margin-bottom: 18px;
				}
			}
		}
	}
	@media screen and (max-width: 470px) {
		.homedescription {
			font-size: 10px;
			&__icon {
				display: flex;
				width: 100%;
				justify-content: center;
				img,
				svg {
					width: 30px;
					height: 30px;
				}
			}
			&__list {
				padding: 40px 20px;
				gap: 60px;
				flex-wrap: wrap;
				justify-content: center;
			}
			&__text {
				&--title {
					text-align: center;
					margin-top: 20px;
					margin-bottom: 18px;
				}
				&--desc {
					text-align: center;
					max-width: 235px;
				}
			}
		}
	}
</style>
