import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// other packages
import VueMobileDetection from "vue-mobile-detection";
import "aos/dist/aos.css";
import { registerScrollSpy } from "vue3-scroll-spy";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

// global css reset.css
import "./assets/css/reset.css";
import "./assets/sass/global.scss";

const app = createApp(App).use(router, VueMobileDetection, LottieAnimation);

registerScrollSpy(app);
app.mount("#app");
