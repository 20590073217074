<template>
	<section class="banner" :class="whiteColor" :style="style">
		<div class="banner__text wrapper">
			<p class="banner__text--underline" v-html="underlineText"></p>
			<h1 class="banner__text--header" v-html="headerText"></h1>
			<p class="banner__text--subheader" :class="subHeaderVisibility">
				{{ subHeader }}
			</p>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		underlineText: String,
		headerText: String,
		subHeader: String,
		height: Number,
		alignItems: String,
		color: String,
		subHeaderVisible: Boolean,
	},
	data() {
		return {
			style: {
				overflow: "hidden",
				alignItems: this.alignItems ? `${this.alignItems}` : "center",
			},
		};
	},
	computed: {
		whiteColor() {
			return this.color === "white" ? "white" : "";
		},
		subHeaderVisibility() {
			return this.subHeaderVisible ? "hidden" : "";
		},
	},
};
</script>

<style lang="scss" scoped>

@import "Banner.scss";

</style>