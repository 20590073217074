<template>
	<div class="cta">
		<div class="cta__button" :style="style" :class="changeCTAType">
			<span class="cta__text">{{ ctaText }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		ctaType: String,
		ctaText: String,
		ctaWidth: Number,
	},
	data() {
		return {
			style: {
				width: this.ctaWidth ? `${this.ctaWidth}px` : "",
				height: this.ctaWidth ? `${this.ctaWidth}px` : "",
			},
		};
	},
	computed: {
		changeCTAType() {
			switch (this.ctaType) {
				case "white":
					return "cta__button--white";
					break;
				case "disable":
					return "cta__button--disable";
					break;

				default:
					return "cta__button--default";
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>